<template>
  <div :class="['image-uploader', $attrs.disabled && 'uploader-disabled']">
    <van-uploader
      v-model="fileList"
      class="image-uploader__feature"
      multiple
      result-type="file"
      :before-read="onBeforeRead"
      :after-read="onAfterRead"
      :accept="$attrs.accept || 'image/*'"
      :preview-image="false"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div
      v-for="(item, idx) in innerFileList"
      :key="idx"
      class="image-uploader__preview-file"
      @click="onFileClick(item, idx)"
    >
      <img :src="item.url" alt="" />
      <van-icon
        v-if="!$attrs.disabled"
        name="clear"
        color="#969799"
        class="close-btn"
        @click.capture.stop="onFileDelete(idx, item)"
      />
    </div>
  </div>
</template>

<script>
import { ImagePreview, Toast } from "vant";
import axios from "@/utils/axios";

const fileUrl = window.globalConfig.VUE_APP_FILE_URL;
export default {
  name: "ImageUploader",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: [],
      innerFileList: [],
      previewFileUrl: `${fileUrl}/file/download?downloadFlag=false&fileName=`
    };
  },
  watch: {
    innerFileList: {
      handler() {
        this.$emit("change", this.innerFileList);
      },
      deep: true
    }
  },
  methods: {
    // 外部调用组件内部方法
    setInnerValue(value) {
      this.innerFileList = value;
      this.asyncFileList();
    },
    onFileClick({ url }, idx) {
      ImagePreview({
        images: [url],
        showIndex: false,
        closeOnPopstate: true
      });
    },
    onFileDelete(idx, file) {
      this.innerFileList.splice(idx, 1);
      this.asyncFileList();
    },
    asyncFileList() {
      this.$set(this, "fileList", this.innerFileList);
    },
    onBeforeRead(file) {
      file = Array.isArray(file) ? file : [file];
      const allowTypes = [
        "jpg",
        "png",
        "jpeg",
        // 支持所有图片格式的上传
        "image"
      ];
      const limit = this.$attrs["max-count"];
      const isLt10 = file.length <= limit && this.innerFileList.length < limit;

      const isValidity = file.every(f => {
        return allowTypes.some(t => f.type.toLocaleLowerCase().includes(t));
      });
      const isLt100MB = file.every(f => f.size / 1024 / 1024 < 100);
      // const isLt100MB = true;
      if (!isLt10) Toast(`附件最多上传${limit}个`);
      if (!isLt100MB) Toast("文件大小不可超过100MB");
      // if (!isValidity) Toast("附件格式不支持");
      // return isLt10 && isValidity && isLt100MB;
      return isLt10 && isLt100MB;
    },
    async onAfterRead(file) {
      file = Array.isArray(file) ? file : [file];
      file.forEach(f => this.uploadFile(f));
    },
    imageCompression(file, pq) {
      const name = file.name;
      const img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        const width = img.width;
        const height = img.height;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const base64 = canvas.toDataURL("image/jpeg", pq); //第二个参数为压缩的比例，越小越模糊。0-1

        const sFile = this.base64ToFile(base64, name);
        const filePath = fileUrl + "/file/upload";
        const fd = new FormData();
        fd.append("files", sFile);

        try {
          const res = await axios.post(filePath, fd);
          const fileInfo = res[0];
          let result = {
            id: fileInfo.id,
            url: this.previewFileUrl + fileInfo.id,
            name: fileInfo.name,
            fileInfo,
            isImage: true
          };
          this.loading.clear();
          this.innerFileList.push(result);
          this.asyncFileList();
        } catch (e) {
          this.loading.clear();
          console.log(e);
        }
      };
    },
    async uploadFile({ file }) {
      this.loading = Toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0
      });
      // const type = file.type;
      const size = file.size / 1024;
      let pq = Number(window.globalConfig.VUE_APP_PICTURE_QUALITY);
      // pq为1则未设置/设置错误或设置为原图
      pq = isNaN(pq) ? 1 : pq || 1;
      if (size > 500 && pq !== 1) {
        return this.imageCompression(file, pq);
      }
      const filePath = fileUrl + "/file/upload";
      const fd = new FormData();
      fd.append("files", file);
      try {
        const res = await axios.post(filePath, fd);
        const fileInfo = res[0];
        let result = {
          id: fileInfo.id,
          url: this.previewFileUrl + fileInfo.id,
          name: fileInfo.name,
          fileInfo,
          isImage: true
        };
        this.innerFileList.push(result);
        this.asyncFileList();
      } catch (e) {
        console.log(e);
      }
      Toast.clear();
    },
    base64ToFile(dataurl, name) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], name, {
        type: mime
      });
    }
  }
};
</script>

<style lang="scss">
.image-uploader {
  $--border-radius: 4px;
  $--image-margin: 4px;

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__feature {
    .van-uploader__wrapper {
      position: relative;
      width: 80px;
      height: 80px;
      text-align: center;
      background-color: #f7f8fa;
      border-radius: $--border-radius;
      margin-right: $--image-margin;
      .van-uploader__upload {
        margin: 0;
        width: 100%;
        height: 100%;
        border-radius: $--border-radius;
        border: 1px dashed #dcdcdc;
      }
    }
  }
  &__preview-file {
    width: 80px;
    height: 80px;
    margin-bottom: $--image-margin;
    margin-right: $--image-margin;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: $--border-radius;
    }
    .close-btn {
      font-size: 20px;
      position: absolute;
      top: -2px;
      right: -2px;
    }
  }
}
.uploader-disabled {
  .image-uploader__feature {
    display: none;
  }
}
</style>
