import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const API = apiPath.psm;

// 作业检查

/**
 * 分页查询检查
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageWorkInspect(params) {
  return axios.get(API + `/work/supervise/work-record/page`, { params });
}

/**
 * 作业检查记录提交
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function commitWorkInspect(data) {
  return axios.post(API + `/work/supervise/work-record/commit`, data);
}

/**
 * 提交之前的查询接口
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function commitWorkInspectBefore(params) {
  return axios.get(API + `/work/supervise/work-record/info`, { params });
}

// 作业检查记录

/**
 * 作业检查记录分页
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageWorkInspectRecord(params) {
  return axios.get(API + `/work/supervise/record/page`, { params });
}

/**
 * 作业检查记录详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoWorkInspectRecordById(id) {
  return axios.get(API + `/work/supervise/record/info/${id}`);
}

/**
 * 分页查询问题清单
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getPageQuestionList(params) {
  return axios.get(API + `/work/supervise/question/page`, { params });
}

/**
 * 问题清单详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getInfoQuestionListById(id) {
  return axios.get(API + `/work/supervise/question/info/${id}`);
}

// 字典

/**
 * 检查人下拉
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectInspectPerson() {
  return axios.get(API + `/work/supervise/dic/checker`);
}

/**
 * 检查类型下拉
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectInspectType() {
  return axios.get(API + `/work/supervise/dic/check-type`);
}

/**
 * 检查项目下拉
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getSelectInspectProject() {
  return axios.get(API + `/work/supervise/dic/check-item`);
}

/**
 * 校验作业票是否已检查
 * @param id
 * @returns {Promise<AxiosResponse<Boolean>>}
 */
export function getCheckStatusByWorkId(id) {
  return axios.get(API + `/work/supervise/record/is-checked/${id}`);
}

// 监督检查记录-提交整改
export function submitRectification(params) {
  return axios.post(API + `/work/supervise/question/result/commit`, params);
}

// 监督检查记录-确认整改
export function confirmRectification(id) {
  const params = {
    state: "3"
  };
  return axios.post(
    `${API}/work/supervise/question/result/confirm/${id}`,
    params
  );
}

// 监督检查记录-退回整改
export function returnRectification(id) {
  const params = {
    state: "1"
  };
  return axios.post(
    `${API}/work/supervise/question/result/confirm/${id}`,
    params
  );
}
