<template>
  <div class="page">
    <van-nav-bar
      fixed
      left-arrow
      title="问题详情"
      @click-left="$router.back()"
    />
    <div class="work-inspect-question-detail">
      <van-form
        ref="rectificationForm"
        :show-error-message="true"
        label-width="100px"
        colon
        :class="[
          'van-form__text-color',
          submitVisible || confirmVisible
            ? 'wrapper-height__feature-btn'
            : 'wrapper-height__not-feature-btn'
        ]"
      >
        <h3 class="title">{{ info.itemName }}</h3>
        <van-field
          :border="false"
          :value="info.deptInvolveName"
          label="问题涉及部门"
          readonly
        />
        <van-field
          :border="false"
          :value="info.content"
          label="问题描述"
          :autosize="true"
          type="textarea"
          readonly
        />
        <van-field label="问题照片" name="uploader">
          <template #input>
            <image-uploader
              ref="uploader-image"
              v-model="info.files"
              :max-count="3"
              :disabled="true"
              accept="image/*,video/*"
            />
          </template>
        </van-field>
        <div class="line-block" />
        <van-field
          :border="false"
          readonly
          :value="info.deptCheckName"
          label="检查部门"
        />
        <van-field
          :border="false"
          readonly
          :value="info.checkerName"
          label="检查人"
        />
        <van-field
          :border="false"
          readonly
          :value="info.ctime"
          label="检查时间"
        />
        <van-field
          :border="false"
          readonly
          :value="info.checkTypeName"
          label="检查类别"
        />
        <van-field
          :border="false"
          readonly
          :value="info.workTypeName"
          label="作业类别"
        />
        <template v-if="isListPage">
          <div class="line-block" />
          <van-field
            :border="false"
            :value="info.ticketName"
            label="作业名称/内容"
            readonly
            class="link"
            @click="toWorkPage"
          />
          <van-field
            :border="false"
            :value="info.facilityName"
            label="作业区域"
            readonly
          />
          <van-field
            :border="false"
            :value="info.workDeptName"
            label="所属部门"
            readonly
          />
          <van-field
            :border="false"
            :value="info.workLeaderName"
            label="作业负责人"
            readonly
          />
          <van-field
            :border="false"
            :value="info.workGuardianName"
            label="监护人"
            readonly
          />
        </template>
        <template v-if="isBy">
          <div class="line-block" />
          <van-field
            v-model="rectificationForm.situation"
            label="整改情况"
            placeholder="请输入整改情况"
            maxlength="500"
            show-word-limit
            required
            clearable
            error-message-align="right"
            type="textarea"
            row="1"
            autosize
            :rules="[
              {
                required: true,
                message: '请填写整改情况',
                trigger: 'onChange'
              }
            ]"
            :readonly="!editable"
          />
          <van-field
            :value="rectificationForm.correctorName"
            label="问题整改人"
            readonly
          />
          <van-field
            v-model="rectificationForm.reason"
            label="原因分析"
            placeholder="请输入原因分析"
            maxlength="500"
            show-word-limit
            required
            clearable
            error-message-align="right"
            type="textarea"
            row="1"
            autosize
            :rules="[
              {
                required: true,
                message: '请填写原因分析',
                trigger: 'onChange'
              }
            ]"
            :readonly="!editable"
          />
          <van-field :value="rectificationForm.photograph" label="照片">
            <div slot="input">
              <van-radio-group
                v-model="rectificationForm.photograph"
                direction="horizontal"
                :disabled="!editable"
                @change="photographChange"
              >
                <van-radio
                  v-for="item in radioOptions"
                  :key="item.value"
                  :name="item.value"
                  >{{ item.label }}</van-radio
                >
              </van-radio-group>
            </div>
          </van-field>
          <van-field
            v-if="rectificationForm.photograph === 1"
            label="整改后照片"
            name="photograph"
            required
            :rules="[
              {
                validator: photographValidator
              }
            ]"
            :error-message="photographError"
          >
            <template #input>
              <image-uploader
                ref="photograph"
                v-model="rectificationForm.files"
                :max-count="3"
                :disabled="!editable"
                accept="image/*"
                @change="handleUploaderChange"
              />
            </template>
          </van-field>
        </template>
      </van-form>
    </div>
    <bottom-fixed-box v-if="submitVisible || confirmVisible">
      <template>
        <van-button
          v-if="submitVisible"
          class="btn-primary"
          @click="handleSubmit"
          >提交整改</van-button
        >
        <van-button
          v-if="confirmVisible"
          class="btn-primary"
          @click="handleConfirm"
          >确认</van-button
        >
        <van-button
          v-if="confirmVisible"
          class="btn-primary"
          @click="handleReturn"
          >退回</van-button
        >
      </template>
    </bottom-fixed-box>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getInfoQuestionListById,
  submitRectification,
  confirmRectification,
  returnRectification
} from "@/api/psm/workInspect";
import { getFileUrlForFileSystem } from "@/utils/file";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import BottomFixedBox from "@/components/general/BottomFixedBox";

export default {
  name: "WorkInspectQuestionDetail",
  components: { ImageUploader, BottomFixedBox },
  inject: ["reload"],
  props: ["id"],
  data() {
    return {
      isListPage: false,
      info: {},
      radioOptions: [
        {
          label: "有",
          value: 1
        },
        {
          label: "无",
          value: 0
        }
      ],
      rectificationForm: {
        questionId: this.id,
        situation: "",
        correctorName: "",
        photograph: 1,
        reason: "",
        files: []
      },
      editable: false,
      photographError: ""
    };
  },
  created() {
    this.isListPage = !!this.$route.query?.isListPage;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    toWorkPage() {
      this.$router.push({
        name: "worktickeDetail",
        params: { id: this.info.ticketId }
      });
    },
    async getInfo() {
      try {
        this.info = await getInfoQuestionListById(this.id);
        const {
          id,
          questionId,
          situation,
          photograph,
          reason,
          files
        } = this.info.checkQuestionResultInfoVO;
        this.rectificationForm = {
          ...this.info.checkQuestionResultInfoVO,
          id: id || undefined,
          questionId: questionId ?? this.id,
          situation: situation ?? "",
          correctorName: (this.info.correctorPersonVOS || [])[0]?.name ?? "",
          photograph: photograph ?? 1,
          reason: reason ?? "",
          files: files ?? []
        };
        await this.$nextTick();
        const fileList =
          this.info?.files.map(file => {
            file.url = getFileUrlForFileSystem(file.id);
            return file;
          }) || [];
        const photographList =
          this.rectificationForm.files.map(file => {
            file.url = getFileUrlForFileSystem(file.id);
            return file;
          }) || [];
        this.$refs["uploader-image"].setInnerValue(fileList);
        this.$refs["photograph"]?.setInnerValue(photographList);
      } catch (e) {
        console.log(e);
      }
    },
    async handleSubmit() {
      const v = await this.$refs["rectificationForm"].validate();
      if (v) return;
      await submitRectification(this.rectificationForm);
      this.$toast.success("提交成功");
      this.reload();
    },
    async handleConfirm() {
      await confirmRectification(this.id);
      this.$toast.success("确认成功");
      this.reload();
    },
    async handleReturn() {
      await returnRectification(this.id);
      this.$toast.success("退回成功");
      this.reload();
    },
    photographChange(val) {
      if (val === 0) {
        this.rectificationForm.files = [];
      }
    },
    handleUploaderChange(val) {
      this.rectificationForm.files = val;
    },
    photographValidator(value) {
      if (!this.rectificationForm.files.length) {
        this.photographError = "整改后照片不能为空";
        return false;
      }
      this.photographError = "";
      return true;
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    submitVisible() {
      const correctorId = (this.info.correctorPersonVOS || [])[0]?.id;
      // 当前状态为待整改，且当前用户是整改人，可以提交整改
      const flag =
        this.isBy &&
        this.info.state === "1" &&
        correctorId === this.userInfo.id;
      return flag;
    },
    confirmVisible() {
      // 当前状态为待确认，且当前用户是创建人，可以确认/退回
      return (
        this.isBy &&
        this.info.state === "2" &&
        this.info.checker === this.userInfo.id
      );
    }
  },
  watch: {
    submitVisible(val) {
      this.editable = val;
    }
  }
};
</script>

<style lang="scss">
.page {
  text-align: left;
  height: 100vh;
  background: #fff;
}
.work-inspect-question-detail {
  overflow: auto;
  padding: 58px 0 0;
  .title {
    padding: 12px 16px 0;
    font-weight: bold;
    font-size: 14px;
    color: #2e2e4d;
    box-sizing: border-box;
    line-height: 22px;
  }
  .link {
    .van-field__control {
      color: $--color-primary;
    }
  }
}
</style>
